<template>
  <v-card>
    <v-form>
      <v-toolbar v-if="!edit" style="margin-bottom: 5px" elevation="1" flat>
        <v-toolbar-title>
          <span class="headline">{{ formTitle }}</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-icon large @click="$router.push('/listagem-produtos')"
          >mdi-arrow-left</v-icon
        >
      </v-toolbar>
      <v-toolbar v-if="edit" style="margin-bottom: 5px" elevation="1" flat>
        <v-toolbar-title>
          <span class="headline">{{ formTitle }}</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-icon large @click="handleClose($event)">mdi-close</v-icon>
      </v-toolbar>
      <v-row no-gutters>
        <v-col cols="12" md="3" class="px-4 py-2 my-6">
          <div class="d-flex flex-wrap justify-space-between">
            <label
              v-if="!capaUrl"
              for="logo"
              style="cursor: pointer; width: 100%"
              class="caption d-flex justify-center flex-wrap border mr-4 px-2"
              title="Add logo cliente"
            >
              <v-icon
                x-large
                color="primary"
                style="height: 200px; width: 100%"
                for="logo"
                >mdi-cloud-upload</v-icon
              >
              <span class="mb-2"> Foto Principal </span>
              <input
                id="logo"
                style="display: none"
                type="file"
                @change="handleUpload('capa', $event)"
              />
            </label>
            <v-img
              v-else
              max-height="250"
              style="width: 15%"
              class="caption d-flex justify-center flex-wrap border mr-4 px-2"
              :src="capaUrl"
            >
            </v-img>
            <div
              v-if="!edit && capaUrl"
              style="cursor: pointer"
              class="w-full d-flex justify-end pr-2 mt-2 hover-red"
              @click="capaUrl = ''"
            >
              <v-icon> mdi-delete </v-icon>
            </div>
            <div
              v-if="
                capaUrl &&
                edit &&
                checkIfPublicado &&
                !checkIfBloqueado &&
                !checkIfCancelado
              "
              style="cursor: pointer"
              class="w-full d-flex justify-end pr-2 mt-2 hover-red"
            >
              <label
                for="logo"
                style="cursor: pointer; width: 100%"
                class="caption d-flex justify-end flex-wrap px-2"
                title="Add logo cliente"
              >
                <v-icon> mdi-camera-retake-outline </v-icon>
                <input
                  id="logo"
                  style="display: none"
                  type="file"
                  @change="handleUpload('capa', $event)"
                />
              </label>
            </div>
            <div class="pa-4">
              <v-alert
                color="primary"
                icon="mdi-camera"
                outlined
                class="mt-4 text-white"
              >
                <span class="text-caption text-color"
                  >Recomendamos o uso de uma imagem do tamanho 1600 x 838
                </span>
              </v-alert>
            </div>
          </div>
        </v-col>
        <v-col cols="9" md="9" class="px-4 my-3">
          <v-select
            v-model="cliente"
            :disabled="
              !checkIfPublicado ||
              checkIfBloqueado ||
              checkIfCancelado ||
              checkIfEncerrado ||
              (checkIfLiberado && isAdminParceiro)
            "
            :items="clientes"
            label="Cliente"
          />
          <v-text-field
            v-model="nomeProduto"
            :rules="nomeProdutoRules"
            :disabled="
              !checkIfPublicado ||
              checkIfBloqueado ||
              checkIfCancelado ||
              checkIfEncerrado ||
              (checkIfLiberado && isAdminParceiro)
            "
            required
            label="Nome do Evento"
          />
          <div class="d-flex flex-wrap">
            <v-select
              v-model="categoria"
              :items="categorias"
              :rules="categoriaRules"
              :disabled="!checkIfPublicado"
              required
              label="Categoria *"
              style="width: 50%"
              class="pr-6"
            />
            <v-select
              v-model="classificacao"
              :items="classificacoes"
              :disabled="
                !checkIfPublicado ||
                checkIfBloqueado ||
                checkIfCancelado ||
                checkIfEncerrado ||
                (checkIfLiberado && isAdminParceiro)
              "
              label="Classificação"
              class="pr-3"
            />
            <v-datetime-picker
              v-model="dataEvento"
              label="Data do Evento*"
              :disabled="
                !checkIfPublicado ||
                checkIfBloqueado ||
                checkIfCancelado ||
                checkIfEncerrado ||
                (checkIfLiberado && isAdminParceiro)
              "
              dateFormat="dd/MM/yyyy"
              datetime="date"
              clearText="limpar"
              class="pr-2"
            >
              <template v-slot:dateIcon>
                <v-icon>mdi-calendar-outline</v-icon>
              </template>
              <template v-slot:timeIcon>
                <v-icon>mdi-clock-outline</v-icon>
              </template>
            </v-datetime-picker>
            <v-text-field
              v-model="codigoFornecedor"
              :disabled="
                !checkIfPublicado ||
                checkIfBloqueado ||
                checkIfCancelado ||
                checkIfEncerrado ||
                (checkIfLiberado && isAdminParceiro)
              "
              label="Código Auxiliar"
              class="pr-3"
            />
            <v-text-field
              v-if="edit"
              v-model="referencia"
              disabled
              label="Número de Referencia"
              class="pr-4"
            />
            <v-select
              v-model="situacao"
              :items="situacoesCupom"
              disabled
              label="Situação"
              class="pr-3"
            />

            <v-select v-if="isHabilitaCadeiras"
              v-model="local"
              :items="locais"
              :disabled="
                !checkIfPublicado ||
                checkIfBloqueado ||
                checkIfCancelado ||
                checkIfEncerrado ||
                (checkIfLiberado && isAdminParceiro)
                || isGetLocais
              "
              label="Local"
            /> 
          
          </div>
        </v-col>
        <v-col cols="12" md="12" class="my-3 px-4">
          <v-textarea
            v-model="especificacoes"
            :rules="especificacaoRules"
            :disabled="
              !checkIfPublicado ||
              checkIfBloqueado ||
              checkIfCancelado ||
              checkIfEncerrado ||
              (checkIfLiberado && isAdminParceiro)
            "
            required
            label="Detalhes do evento"
          />
          <div class="d-flex flex-wrap">

            <v-datetime-picker
              v-model="dataInicio"
              label="Inicio Venda*"
              :disabled="
                !checkIfPublicado ||
                checkIfBloqueado ||
                checkIfCancelado ||
                checkIfEncerrado ||
                (checkIfLiberado && isAdminParceiro)
              "
              dateFormat="dd/MM/yyyy"
              datetime="date"
              clearText="limpar"
              class="pl-3"
            >
              <template v-slot:dateIcon>
                <v-icon>mdi-calendar-outline</v-icon>
              </template>
              <template v-slot:timeIcon>
                <v-icon>mdi-clock-outline</v-icon>
              </template>
            </v-datetime-picker>
           
            <v-datetime-picker
              v-model="dateTermino"
              label="Término Venda*"
              :disabled="
                !checkIfPublicado ||
                checkIfBloqueado ||
                checkIfCancelado ||
                checkIfEncerrado ||
                (checkIfLiberado && isAdminParceiro)
              "
              dateFormat="dd/MM/yyyy"
              datetime="date"
              clearText="limpar"
              class="pl-3"
            >
              <template v-slot:dateIcon>
                <v-icon>mdi-calendar-outline</v-icon>
              </template>
              <template v-slot:timeIcon>
                <v-icon>mdi-clock-outline</v-icon>
              </template>
            </v-datetime-picker>

            <v-select
              v-model="tipoIngresso"
              label="Tipo de Ingresso"
              :disabled="
                !checkIfPublicado ||
                checkIfBloqueado ||
                checkIfCancelado ||
                checkIfEncerrado ||
                (checkIfLiberado && isAdminParceiro)
              "
              :items="tiposIngressos"
              class="pl-2"
            />
          </div>
        </v-col>
        <v-col v-if="isRoot && isGratuitoHabilitado " cols="3" md="3" class="my-3 px-4" >
          <v-switch
            v-model="gratuito"
            inset
            :disabled="edit"
            label="Gratuito"
          ></v-switch>
        </v-col>
        <v-col cols="3" md="3" class="my-3 px-4">
          <v-switch
            v-model="timeline"
            inset
            :disabled="
              !checkIfPublicado ||
              checkIfCancelado ||
              checkIfBloqueado ||
              (checkIfLiberado && isAdminParceiro)
            "
            label="Incluir na Timeline"
          ></v-switch>

          <v-switch
            v-model="mostataxa"
            inset
            :disabled="
              checkIfCancelado ||
              checkIfBloqueado ||
              (checkIfLiberado && isAdminParceiro)
            "
            label="Mostrar Taxa Conveniência"
          ></v-switch>
        </v-col>
        <v-col v-if="timeline" cols="9" md="9" class="my-3 px-4">
          <div class="d-flex"></div>
          <v-select
            v-model="assunto"
            :items="assuntos"
            :disabled="
              !checkIfPublicado ||
              checkIfBloqueado ||
              checkIfCancelado ||
              checkIfEncerrado ||
              (checkIfLiberado && isAdminParceiro)
            "
            label="Assunto"
          />
        </v-col>

        <!-- Ações -->
        <v-col cols="12" md="12" class="my-3 px-4 d-flex justify-end">
          
          <v-btn
            v-if="edit"
            color="primary"
            class="mr-5"
            @click="modalCopiarIngresso = true"
          >
            Copiar
          </v-btn>
          
          <v-btn
            v-if="edit && isRoot"
            color="primary"
            class="mr-5"
            @click="dialogIngressoTaxa = true"
          >
            Taxa Conveniência
          </v-btn>

          <v-btn
            v-if="edit"
            color="primary"
            :disabled="checkIfBloqueado || checkIfCancelado || checkIfEncerrado"
            class="mr-5"
            @click="openCategoria()"
          >
            Categoria
          </v-btn>
          <v-btn
            v-if="edit"
            color="primary"
            :disabled="
              (!checkIfCadastrado && !checkIfLiberado && checkIfCancelado) ||
              checkIfEncerrado
            "
            class="mr-5"
            @click="confirmacaoDescontoCancelar = true"
          >
            Cancelar
          </v-btn>
          <v-btn
            v-if="edit"
            color="primary"
            :disabled="checkIfPublicado"
            class="mr-5"
            @click="confirmacaoDescontoBloquear = true"
          >
            Bloquear
          </v-btn>
          <v-btn
            v-if="edit"
            color="primary"
            :disabled="!checkIfCadastrado"
            class="mr-5"
            @click="submitIngresso(2)"
          >
            Liberar
          </v-btn>
          <v-btn
            v-if="edit && isRoot"
            :disabled="!checkIfLiberado"
            class="mr-5"
            color="primary"
            @click="confirmacaoPublicar = true"
          >
            Publicar
          </v-btn>
          <v-btn
            color="primary"
            :disabled="
              (checkIfLiberado && isAdminParceiro) ||
              checkIfBloqueado ||
              checkIfCancelado ||
              !checkIfPublicado ||
              checkIfEncerrado
            "
            class="mr-5"
            @click="submitIngresso(1)"
          >
            Salvar
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
    <v-dialog v-model="modalCopiarIngresso" persistent max-width="460">
      <v-card>
        <v-card-title class="text-h5"> Copiar Ingresso </v-card-title>
        <v-card-text> Deseja copiar o ingresso indicado? </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="modalCopiarIngresso = false"> Voltar </v-btn>
          <v-btn
            color="error"
            :loading="copyLoading"
            text
            @click="copiarIngresso()"
          >
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="confirmacaoPublicar" persistent max-width="460">
      <v-card>
        <v-card-title class="text-h5">
          Deseja Realmente Prosseguir?
        </v-card-title>
        <v-card-text>
          O desconto será disponibilizado para os usuários. Este procedimento
          bloqueará futuras alterações.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="confirmacaoPublicar = false"> Voltar </v-btn>
          <v-btn color="error" text @click="submitIngresso(5)">
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="confirmacaoProduto" persistent max-width="460">
      <v-card>
        <v-card-title class="text-h5">
          Deseja Realmente Prosseguir?
        </v-card-title>
        <v-card-text
          >O Produto a seguir não estará mais disponível e a ação é
          irreversível.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="confirmacaoProduto = false"> Desistir </v-btn>
          <v-btn color="error" text @click="submitIngresso(4)">
            Continuar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="confirmacaoDescontoBloquear" persistent max-width="460">
      <v-card>
        <v-card-title class="text-h5">
          Deseja Realmente Prosseguir?
        </v-card-title>
        <v-card-text
          >O Desconto a seguir não estará mais disponível e a ação é
          irreversível.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="confirmacaoDescontoBloquear = false">
            Desistir
          </v-btn>
          <v-btn color="error" text @click="submitIngresso(3)">
            Continuar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="confirmacaoDescontoCancelar" persistent max-width="460">
      <v-card>
        <v-card-title class="text-h5">
          Deseja Realmente Prosseguir?
        </v-card-title>
        <v-card-text
          >O Desconto a seguir não estará mais disponível e a ação é
          irreversível.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="confirmacaoDescontoCancelar = false">
            Desistir
          </v-btn>
          <v-btn color="error" text @click="submitIngresso(4)">
            Continuar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogIngresso" persistent max-width="1700">
      <modulo-ingresso
        v-if="edit"
        :tipoIngresso="tipoIngresso"
        :id="produtos.id"
        :situacao="produtos.situacaoId"
        :finalDate="produtos.dataValidade"
        :fotoMapa="produtoMapa"
        @onClose="dialogIngresso = false"
        class="mt-4"
      />
    </v-dialog>

    <v-dialog v-model="dialogIngressoCategoria" persistent max-width="1700">
      <modulo-ingresso-categoria
        v-if="edit"
          :edit="edit"
          :item="produtos.localId"
          :key="key"
        @onClose="dialogIngressoCategoria = false"
        class="mt-4"
      />
    </v-dialog>

    <v-dialog v-model="dialogIngressoTaxa" persistent max-width="1700">
      <modulo-ingresso-taxa
        v-if="edit"
        :id="produtos.id"        
        @onClose="dialogIngressoTaxa = false"
        class="mt-4"
      />
    </v-dialog>

    <v-dialog v-model="confirmacaoConteudoGratuito" persistent max-width="460">
      <v-card>
        <v-card-title class="text-h5">
          Deseja Realmente Prosseguir?
        </v-card-title>
        <v-card-text
          >O Ingresso a seguir não ira ter mais valor de cobrança e a ação é
          irreversível.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="confirmacaoConteudoGratuito = false">
            Desistir
          </v-btn>
          <v-btn color="error" text @click="conteudoGratuito()">
            Continuar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-card>
</template>

<script>
import {
  IngressoService,
  ProdutoService,
  TiposService,
} from "../../../services";
import { Role } from "../../../constants";
import moduloIngresso from "./moduloIngresso.vue";
import moduloIngressoCategoria from "./moduloIngressoCategoria.vue";
import moduloIngressoTaxa from "./moduloIngressoTaxa.vue";

import moment from "moment";

export default {
  name: "formularioIngresso",
  components: {
    moduloIngresso,
    moduloIngressoTaxa,
    moduloIngressoCategoria,
  },

  props: {
    edit: {
      type: Boolean,
      default: false,
    },
    ingresso: {
      type: Boolean,
      default: false,
    },
    produtos: {
      type: Object,
    },
  },
  data() {
    return {
      assuntos: [],
      assunto: 0,
      dialogIngresso: false,
      dialogIngressoTaxa: false,
      dialogIngressoCategoria: false,
      status: ["Ativo", "Desativado", "Inválido"],
      dataInicio: "",
      dateTermino: "",
      menu2: false,
      classificacoes: [],
      dataToFormat: null,
      quantity: null,
      codigoFornecedor: "",
      confirmacaoPublicar: false,
      modalCopiarIngresso: false,
      especificacaoCupom: "",
      dataEvento: "",
      useFotoProduto: false,
      promocao: null,
      menu: false,
      medidas: [],
      unidadeMedida: null,
      confirmacaoProduto: false,
      confirmacaoDescontoBloquear: false,
      confirmacaoDescontoCancelar: false,
      confirmacaoConteudoGratuito: false,
      fotos: null,
      hasDiscount: false,
      cliente: "",
      clientes: [],
      nomeProduto: "",
      situacao: { text: "Em Cadastramento", value: 1 },
      situacoesCupom: [
        { text: "Em Cadastramento", value: 1 },
        { text: "Liberado", value: 2 },
        { text: "Bloqueado", value: 3 },
        { text: "Cancelado", value: 4 },
        { text: "Publicado", value: 5 },
      ],
      local: "",
      locais: [],
      tipoIngresso: "",
      dataCriacao: "23/10/2021",
      classificacao: "",
      categorias: [],
      timeline: false,
      mostataxa: true,
      hasNotify: false,
      nomeProdutoRules: [(v) => !!v || "O Nome é obrigatório"],
      categoriaRules: [(v) => !!v || "A Categoria é obrigatória"],
      especificacaoRules: [(v) => !!v || "A Especificação é obrigatória"],
      tipoPromocaoRules: [(v) => !!v || "O tipo de promoção é obrigatório"],
      descricaoRules: [(v) => !!v || "O Nome do Cupom é obrigatória"],
      quantidadeRules: [(v) => !!v || "A quantidade é obrigatória"],
      categoria: "",
      referencia: "",
      especificacoes: "",
      capaUrl: null,
      capaTpMidia: null,
      tiposIngressos: [],
      tipoPromocao: "",
      precoTotal: 0,
      precoDesconto: 0,
      payload: {},
      file: {},
      id: null,
      formData: {},
      produtoId: null,
      produtosList: [],
      saveProduto: null,
      editInfo: {},
      percentual: null,
      produtoMapa: null,
      promoAberta: null,
      planoId: null,
      isConteudoGratuito: false,
      gratuito: false,
      hasInstalacao: localStorage.getItem("instalacao"),
    };
  },
  created() {
    this.getFiltros();
    this.getCategorias();
    this.getAssuntos();
    this.getClassificacao();
    this.getTiposIngressos();
  },
  mounted() {
    if (window.location.pathname === "/listagem-produtos") {
      this.editInfo = { ...this.produtos };
      this.id = this.produtos?.id;
      this.capaId = this.produtos?.produtoImagem
        ? this.produtos?.produtoImagem[0].id
        : null;
      this.produtoMapa =
        this.produtos?.produtoImagem.length > 1
          ? this.produtos?.produtoImagem[1].midiaUrl
          : null;
      this.categoria = this.produtos?.categoriaId;
      this.referencia = this.produtos?.codigo;
      this.classificacao = this.produtos?.classificacaoEventoId;
      this.cliente = this.produtos?.clienteId;
      this.codigoFornecedor = this.produtos?.codigoFornecedor;
      this.capaUrl = this.produtos?.produtoImagem
        ? this.produtos?.produtoImagem[0].midiaUrl
        : null;
      this.especificacoes = this.produtos?.ticketDescricao;
      this.nomeProduto = this.produtos?.descricao;
      this.tipoIngresso = this.produtos?.tipoIngressoId;
      this.unidadeMedida = this.produtos?.sigla;
      this.situacao = this.produtos?.situacaoId;
      this.dataCriacao = moment(this.produtos?.dataCadastro).format(
        "D/M/Y [às] h:mm A z"
      );
      this.dataEvento = new Date(this.produtos?.ticketDateEvento);
      this.dataInicio =  new Date(this.produtos?.dataInicio);
      this.dateTermino =  new Date(this.produtos?.dataValidade);
      this.tipoPromocao = this.editInfo.tipoDescontoId;
      this.precoDesconto = this.produtos?.valorDesconto;
      this.precoTotal = this.produtos?.valorTotal;
      this.hasDiscount = this.produtos?.valorDesconto ? true : false;
      this.produtoId = this.produtos?.id;
      this.percentual = this.produtos?.percentualDesconto;
      this.quantity = this.produtos?.quantidade;
      this.timeline = this.produtos?.assuntoId ? true : false;
      this.assunto = this.produtos?.assuntoId ? this.produtos?.assuntoId : null;
      this.isGratuito = this.produtos?.isGratuito ? true : false;
      this.mostrataxa = this.produtos?.isMostraTaxa ? true : false;
      this.gratuito = this.isGratuito ;
      this.getLocais();
      console.log(this.produtos);
      this.local = this.produtos?.localId;
    }
  },
    computed: {
      // formatedDataInicio() {
      //   return this.formatDate(this.dataInicio);
      // },
      isGetLocais(){
        console.log(this.cliente)
        if (this.cliente) {
          this.getLocais();
          return false;
        } else {
        return true;
      }
      },
    isGratuitoHabilitado() {
      return this.$store.getters["instalacao/getInstalacao"].habilitaGratuito;
    },
    isHabilitaCadeiras() {
      return this.$store.getters["instalacao/getInstalacao"].habilitaCadeiras;
    },
    isGratuitoVisivel(){      
      return this.isConteudoGratuito == false;
    },
    isRoot() {
      return this.$store.getters["user/getUser"].roleId == Role.ROOT
        ? true
        : false;
    },
    isAdminParceiro() {
      return this.$store.getters["user/getUser"].roleId == Role.ADMIN_PARCEIRO
        ? true
        : false;
    },
    // formatedDataFinal() {
    //   return this.formatDate(this.dateTermino);
    // },
    checkTipoDesconto() {
      return this.tipoPromocao === 2
        ? false
        : this.tipoPromocao === 4
        ? false
        : this.tipoPromocao === 1
        ? false
        : true;
    },
    checkIfCadastrado() {
      return this.editInfo.situacaoId === 1 ? true : false;
    },
    checkIfEncerrado() {
      return this.editInfo.situacaoId === 9 ? true : false;
    },
    checkSituacaoLiberarCadastrar() {
      return this.editInfo.situacaoId !== 1 || this.editInfo.situacaoId !== 2
        ? true
        : false;
    },
    checkIfBloqueado() {
      return this.editInfo.situacaoId === 3 ? true : false;
    },
    checkIfCancelado() {
      return this.editInfo.situacaoId === 4 ? true : false;
    },
    checkIfLiberado() {
      return this.editInfo.situacaoId === 2 ? true : false;
    },
    checkIfPublicado() {
      return this.editInfo.situacaoId !== 5 ? true : false;
    },
    validated() {
      if (
        this.cliente &&
        this.nomeProduto &&
        this.categoria &&
        this.unidadeMedida &&
        this.especificacao &&
        this.capaUrl &&
        this.capaUrl != []
      ) {
        return true;
      } else {
        return false;
      }
    },
    validatedCupom() {
      if (
        this.especificacaoCupom &&
        this.unidadeMedida &&
        this.especificacao &&
        this.capaUrl &&
        this.capaUrl != []
      ) {
        return true;
      } else {
        return false;
      }
    },
    checkRole() {
      const user = this.$store.state.user.user.roleLabel;
      if (user === "Root") {
        return false;
      } else {
        return true;
      }
    },
    gotDiscount() {
      if (this.hasDiscount === true) {
        return "";
      } else {
        return "O produto possui desconto?";
      }
    },
    formTitle() {
      if (window.location.pathname === "/cadastrar-produtos") {
        return "Cadastrar novo Produto";
      } else if (window.location.pathname === "/cadastrar-cupom") {
        return "Cadastrar Novo Desconto/Cupom";
      } else {
        return "Editar";
      }
    },
    hasPromotion() {
      if (this.tipoPromocao === "Simples") {
        return true;
      } else {
        return false;
      }
    },
    isTipoPromocaoLimitado() {
      return this.tipoPromocao == 3;
    },
  },
  methods: {
    allowedDateInicio(val) {
      let dataInicial = moment(new Date()).format("Y-MM-DD");
      return val >= dataInicial;
    },
    allowedDateEvento(val) {
      return val > this.dataInicio;
    },
    allowedDateTermino(val) {
      return val > this.dataInicio;
    },
    handleClose() {
      this.$emit("onClose");
    },
    // formatDate(date) {
    //   if (!date) return null;

    //   const [year, month, day] = date.split("-");
    //   return `${day}/${month}/${year} 00:00`;
    // },
    filterNull(obj) {
      return obj !== null;
    },
    deleteCapa() {
      ProdutoService.deleteCapa(this.produtoId)
        .then((response) => {
          this.$root.showSucesso("Operação realizada com sucesso");
          this.capaUrl = "";
          console.log(response);
        })
        .catch((error) => {
          if (error.status != 401) {
            this.$root.showErro(error.data);
          }
        })
        .finally(() => {
          this.loadingSalvar = false;
        });
    },
    async getFiltros() {
      try {
        const res = await ProdutoService.getFiltros();
        this.clientes = res.data.estabelecimentos.map((estabelecimentos) => ({
          text: estabelecimentos.descricao,
          value: estabelecimentos.id,
        }));
      } catch (error) {
        this.$root.showErro(error.data);
      }
    },
    getCategorias() {
      ProdutoService.getCategoriaSemVinculo()
        .then((response) => {
          let categoriaSemFiltro = response.data.content.map((c) => ({
            text: c.categoriaTitle,
            value: c.categoriaId,
            planoId: c.planoId,
          }));
          this.categorias = categoriaSemFiltro.filter((c) => c.planoId >= 201);
        })
        .catch((error) => {
          if (error.status != 401) {
            this.$root.showErro(error.data);
          }
        });
    },

    conteudoGratuito() {
      this.isConteudoGratuito = true;
      this.$root.showSucesso("Sucesso!");
    },


    openCategoria() {

      console.log(this.tipoIngresso, "tipo ingresso teste")
      if(this.tipoIngresso == "5"){
        this.dialogIngressoCategoria = true;
      }else{
        this.dialogIngresso = true;
      }
    },
    submitIngresso(type) {
      if (!this.especificacoes || this.especificacoes == "") {
        return this.$root.showErro("A especificação é um campo necessário");
      }
      if (
        moment(this.dataEvento).valueOf() < moment(this.dataInicio).valueOf()
      ) {
        return this.$root.showErro(
          "A data do evento deve ser superior a data de início"
        );
      }
      if (this.precoDesconto > this.precoTotal) {
        return this.$root.showErro(
          "O valor do desconto não pode ser superior ao valor total"
        );
      }
      if (!this.dataInicio) {
        return this.$root.showErro("Defina uma data de início");
      }
      if (!this.nomeProduto) {
        return this.$root.showErro("O nome do evento é um campo necessário");
      }
      if (!this.dateTermino) {
        return this.$root.showErro("Defina uma data de termino");
      }
      if (!this.capaUrl) {
        return this.$root.showErro("Defina uma imagem para o evento");
      }
      if (!this.tipoIngresso) {
        return this.$root.showErro("Defina um tipo de ingresso");
      }
      if (type == 1 && window.location.pathname == "/listagem-produtos") {
        type = this.editInfo.situacaoId;
      }

      const payload = {
        assuntoId: this.timeline ? this.assunto : "",
        categoriaId: this.categoria,
        classeId: 70,
        ...(this.id && { id: this.id }),
        classificacaoId: this.classificacao,
        clienteId: this.cliente,
        codigoFornecedor: this.codigoFornecedor,
        dataEvento: this.dataEvento,
        dataInicio: this.dataInicio,
        dataValidade: this.dateTermino,
        descricao: this.nomeProduto,
        especificacao: this.especificacoes,
        imagemIngresso: {
          id: 0,
          isCapa: true,
          isMapa: false,
          legenda: "",
          midiaUrl: this.capaUrl,
          tpMidia: "IMAGEM",
        },
        isTimeline: false,
        tipoIngressoId: this.tipoIngresso,
        situacaoId: type,
        isGratuito: this.gratuito,
        isMostraTaxa: this.mostrataxa,
        localId: this.local,
      };
      IngressoService.cadastroIngresso(payload)
        .then(() => {
          this.confirmacaoDescontoCancelar = false;
          this.confirmacaoDescontoBloquear = false;
          this.confirmacaoPublicar = false;
          this.confirmacaoConteudoGratuito = false;
          this.situacao = type;
          let a = 0;
          this.editInfo.situacaoId = type;
          this.$emit("submit", a++);
          this.$root.showSucesso("Operação realizada com sucesso");
          this.$router.push("/listagem-produtos");
        })
        .catch((error) => {
          if (error.status != 401) {
            this.$root.showErro(error.data);
          }
        });
    },
    getAssuntos() {
      TiposService.getAllAssuntos()
        .then((response) => {
          this.assuntos = response.data.map((p) => ({
            text: p.descricao,
            value: p.id,
          }));
        })
        .catch((error) => {
          if (error.status != 401) {
            this.$root.showErro(error.data);
          }
        });
    },
    getClassificacao() {
      TiposService.getClassificacaoEventos()
        .then((response) => {
          this.classificacoes = response.data.map((m) => ({
            text: m.descricao,
            value: m.id,
          }));
        })
        .catch((error) => {
          if (error.status != 401) {
            this.$root.showErro(error.data);
          }
        });
    },
    getLocais() {
      IngressoService.getLocaisCliente(this.cliente)
        .then((response) => {
          this.locais = response.data.map((l) => ({
            text: l.descricao,
            value: l.id,
          }));
        })
        .catch((error) => {
          if (error.status != 401) {
            this.$root.showErro(error.data);
          }
        });
    },
    getTiposIngressos() {
      TiposService.getTipoIngresso()
        .then((response) => {
          this.tiposIngressos = response.data.map((m) => ({
            text: m.descricao,
            value: m.id,
          }));
        })
        .catch((error) => {
          if (error.status != 401) {
            this.$root.showErro(error.data);
          }
        });
    },
    copiarIngresso() {
      this.copyLoading = true;

      this.loadingSalvar = true;
      ProdutoService.copiarIngresso(this.produtoId)
        .then((response) => {
          console.log(response);
          this.modalCopiarIngresso = false;
          this.$root.showSucesso("Operação realizada com sucesso");
          let a = 0;
          this.$emit("submit", a++);
        })
        .catch((error) => {
          if (error.status != 401) {
            this.$root.showErro(error.data);
          }
        })
        .finally(() => {
          this.modalCopiarIngresso = false;
          this.loadingSalvar = false;
          this.copyLoading = false;
        });
    },
    handleUpload(type, event) {
      if (event && event.target && event.target.files.length > 0) {
        this.file = event.target.files[0];
        this.formData = new FormData();
        this.formData.append("file", this.file);
        this.formData.append("name", this.file.name);
        this.loadingMidia = true;
        ProdutoService.uploadMidia(this.formData)
          .then((response) => {
            this.capaUrl = response.data.midiaUrl;
            this.capaTpMidia = response.data.tpMidia;
            this.capaId = response.data.id;
            if (this.edit) {
              this.submitIngresso(this.situacao);
            }
          })
          .catch((erro) => {
            this.$root.showErro(erro.data);
          })
          .finally(() => {
            this.loadingMidia = false;
          });
      }
    },
  },
};
</script>

<style lang="scss">
.w-full {
  width: 100%;
}
.text-color-red {
  color: #e30613;
}
.color-detail {
  color: #757575 !important;
}

</style>
